let $body;
let $hamburger;
let $menuMobile;
let $header;
let $headerHeight;
let $heroSlider;
let $carouselSlider;
let $logoSlider;
let $contentContainer;
let $social;
let $tableOfContents;
let $tabs;
let $tabsContent;
let $toTopButton;
let $toggleAuthorPopup;
let $authorPopup;
let $copyrightBlock;
let $copyrightContent;
let $queryString;
let $readMoreButton;
let $readMoreContainer;
let $observationContainer;
let $addToCalendarSelect;
let $videoBlock;
let $programParts;
let $headerSlider;
let $headerSliderThumbnails;
let $pictureSlider;
let $pictureSliderOverlay;
let $closeOverlay;
let $audioElem;
let $audioPlayer;
let $ad;
let $videoPlayer;
let accordion;

$(document).ready(($) => {
  console.log('%cLinku Novio', 'font-size: 2em; font-family: sans-serif; color: white; background-color:#18aaff; padding:4px 8px; border-radius: 4px');

  $body = $('body');
  $hamburger = $('.js-menu-toggle');
  $carouselSlider = $('.js-carousel-slider');
  $header = $('.js-header');
  $menuMobile = $('.js-menu-mobile');
  $heroSlider = $('.js-hero-slider');
  $logoSlider = $('.js-logo-slider');
  $videoBlock = $('.wp-block-video');
  $contentContainer = $('.js-item-content');
  $social = $('.js-social-share');
  $tableOfContents = $('.js-toc');
  $tabs = $('.js-tab');
  $tabsContent = $('.js-tab-content');
  $toTopButton = $('.js-to-top-button');
  $toggleAuthorPopup = $('.js-toggle-author-popup');
  $authorPopup = $('.js-author-popup');
  $copyrightBlock = $('.js-copyright');
  $copyrightContent = $('.js-copyright-content');
  $headerHeight = $header.outerHeight();
  $readMoreButton = $('.js-read-more-button');
  $readMoreContainer = $('.js-read-more-container');
  $observationContainer = $('.js-observation-container');
  $addToCalendarSelect = $('.js-add-to-calendar');
  $programParts = $('.js-program-part');
  $videoPlayer = $('.js-video-detail__video-overlay');
  $headerSlider = $('.js-header-slider');
  $headerSliderThumbnails = $('.js-header-slider-thumbnails');
  $pictureSlider = $('.js-picture-slider');
  $pictureSliderOverlay = $('.js-picture-slider-overlay');
  $closeOverlay = $('.js-close-overlay');
  $audioPlayer = $('.js-audio-player');
  $queryString = window.location.search;

  if ($queryString == '?open-flipbook') {
    // delay to make sure everything needed is loaded
    setTimeout(() => {
      $('.open-flipbook').trigger('click');
    }, 1000);
  }

  $hamburger.on('click', function (e) {
    if ($hamburger.hasClass('is-open')) {
      $(this).removeClass('is-open');
      $body.removeClass('menu-is-open');
      $menuMobile.slideToggle();
    } else {
      $(this).addClass('is-open');
      $menuMobile.slideToggle();
      $body.addClass('menu-is-open');
    }
  });

  $observationContainer.each(function () {
    const readMoreButton = $(this).find('.js-read-more-button');
    const readMoreContainer = $(this).find('.js-read-more-container');
    const observationContent = $(this).find('.js-observation-content');
    addClickReadMore(readMoreButton, readMoreContainer, observationContent);
  });
  $programParts.each(function () {
    const readMoreButton = $(this).find('.js-read-more-button');
    const readMoreContainer = $(this).find('.js-read-more-container');
    const partContent = $(this).find('.js-program-content');
    addClickReadMore(readMoreButton, readMoreContainer, partContent);
  });

  $toTopButton.on('click', () => {
    $('html, body').animate({ scrollTop: 200 }, { duration: 500, queue: false });
  });

  $heroSlider.each((index, value) => {
    const heroSlider = tns({
      container: value,
      items: 1,
      mouseDrag: true,
      speed: 600,
      nav: true,
      navPosition: 'bottom',
      autoplayButton: false,
      autoplayPosition: 'bottom',
      autoplayText: ['<i class="fal fa-play"></i>', '<i class="fal fa-pause"></i>'],
      controlsText: ['<i class="fal fa-chevron-left"></i>', '<i class="fal fa-chevron-right"></i>'],
      controls: true,
      autoplay: true,
      autoHeight: false,
    });
    setLabelsAfterSliderRendered(heroSlider);
  });

  let headerSlider;
  let thumbnailSlider;

  $headerSlider.each((index, value) => {
    headerSlider = tns({
      container: value,
      items: 1,
      mouseDrag: true,
      speed: 600,
      nav: true,
      navPosition: 'bottom',
      autoplayButton: false,
      mode: 'gallery',
      autoplayText: ['', ''],
      controlsText: ['', ''],
      controls: false,
      autoplay: false,
      autoHeight: true,
      center: true,
      loop: true,
      autoplayHoverPause: true,
      navContainer: '.js-header-slider-thumbnails',
    });
    setLabelsAfterSliderRendered(headerSlider);
  });

  $headerSliderThumbnails.each((index, value) => {
    const thumbnailSlider = tns({
      container: value,
      items: 5,
      mouseDrag: true,
      speed: 600,
      nav: false,
      autoplayButton: false,
      autoplayText: ['', ''],
      controlsText: ['', ''],
      controls: true,
      autoplay: false,
      autoHeight: false,
      autoplayHoverPause: true,
      loop: false,
      fixedWidth: 85,
      gutter: 12,
    });
    setLabelsAfterSliderRendered(thumbnailSlider);

    headerSlider.events.on('indexChanged', (evt) => {
      const indexToGoTo = evt.index > headerSlider.getInfo().slideCount ? 0 : evt.index - 1;
      thumbnailSlider.goTo(indexToGoTo);
    });
  });

  let pictureSlider;

  $pictureSlider.each((index, value) => {
    pictureSlider = tns({
      container: value,
      items: 1,
      center: true,
      controlsText: ['<i class="fal fa-arrow-left"></i>', '<i class="fal fa-arrow-right"></i>'],
      nav: false,
      mouseDrag: true,
      loop: false,
      onInit: hideSlideItems,
      gutter: 24,
      navContainer: '.js-picture-slider-overlay',
      responsive: {
        991: {
          gutter: 0,
        },
      },

    });
    setLabelsAfterSliderRendered(pictureSlider);

    // bind function to event
    pictureSlider.events.on('indexChanged', hideSlideItems);
  });

  function hideSlideItems(info, eventName) {
    const containerId = info.container.id;
    const $sliderItems = $(`#${containerId} .tns-item`);
    const $activeSlide = $('.tns-slide-active');

    $sliderItems.addClass('is-hidden');
    $activeSlide.removeClass('is-hidden');
    $activeSlide.prev().removeClass('is-hidden');
    $activeSlide.next().removeClass('is-hidden');
  }

  $pictureSliderOverlay.each((index, value) => {
    const pictureSliderOverlay = tns({
      container: value,
      items: 1,
      center: true,
      controlsText: ['<i class="fal fa-arrow-left"></i>', '<i class="fal fa-arrow-right"></i>'],
      nav: false,
      mouseDrag: true,
      loop: false,
    });
    setLabelsAfterSliderRendered(pictureSliderOverlay);

    pictureSlider.events.on('indexChanged', (evt) => {
      const indexToGoTo = evt.index > pictureSlider.getInfo().slideCount ? 0 : evt.index - 2;
      pictureSliderOverlay.goTo(evt.index);
    });
  });

  $closeOverlay.on('click', () => {
    $('.b-picture-slider__overlay-background').removeClass('is-open');
  });

  $('.js-slide-container').on('click', () => {
    if ($(window).width() > 767) {
      $('.b-picture-slider__overlay-background').addClass('is-open');
    }
  });

  $carouselSlider.each((index, value) => {
    const carouselSlider = tns({
      container: value,
      items: 1,
      mouseDrag: true,
      speed: 1000,
      nav: true,
      navPosition: 'bottom',
      autoplayButton: false,
      autoplayButtonOutput: false,
      autoplayPosition: 'bottom',
      controlsText: ['<i class="fal fa-arrow-left"></i>', '<i class="fal fa-arrow-right"></i>'],
      // controls: true,
      autoplay: false,
      autoHeight: false,
      center: true,
      responsive: {
        992: {
          fixedWidth: 650,
          items: 3,
        },
        1200: {
          fixedWidth: 792,
        },
      },
    });
    setLabelsAfterSliderRendered(carouselSlider);
  });

  $copyrightBlock.each(function () {
    let captionHeight = 0;
    if ($(this).find('figcaption').length !== 0) {
      captionHeight = $(this).find('figcaption').outerHeight() + 6;
    }
    console.log('captionheight ', captionHeight);
    $(this).find('.js-copyright-content').css('bottom', captionHeight);
  });

  $logoSlider.each((index, value) => {
    // $.when(
    const logoSlider = tns({
      container: value,
      items: 2,
      mouseDrag: false,
      speed: 600,
      controlsContainer: '.js-nav-container',
      controlsText: ['', ''],
      controls: true,
      nav: false,
      autoplay: true,
      autoplayButtonOutput: false,
      autoHeight: true,
      center: true,
      responsive: {
        640: {
          items: 3,
        },
        800: {
          items: 4,
        },
        900: {
          items: 6,
        },
        1200: {
          items: 8,
        },
      },
    });

    setLabelsAfterSliderRendered(logoSlider);
  });

  $videoBlock.each(function () {
    console.log($(this).find('video'));
    if ($(this).find('video').attr('autoplay') !== 'autoplay') {
      $(this).addClass('paused');
    }

    const video = $(this).find('video').get(0);
    let playing = false;

    // video.removeAttribute('controls');
    $(this).click(function () {
      if (playing) {
        video.pause();
        $(this).addClass('paused');
      } else {
        video.play();
        $(this).removeClass('paused');
      }
      playing = !playing;
    });
  });

  $toggleAuthorPopup.on('click', function (e) {
    e.stopPropagation();
    $authorPopup.each(function () {
      if ($(this).hasClass('show')) {
        $(this).fadeOut().removeClass('show');
      }
    });
    $(this).find('.js-author-popup').fadeIn().addClass('show');
  });

  $videoPlayer.on('click', function () {
    $(this).find('.c-video-detail__video').toggleClass('is-visible');
    $(this).toggleClass('is-hidden');

    const videoUrl = $('.c-video-detail__video iframe').attr('src');
    $('.c-video-detail__video iframe').attr('src', `${videoUrl}&autoplay=1`);
  });

  $body.click(() => {
    $authorPopup.fadeOut().removeClass('show');
  });

  $audioPlayer.on('click', function () {
    const player = $(this).find('audio')[0];
    player.paused ? player.play() : player.pause();
  });

  // Gravityforms upload button
  $('.ginput_container_fileupload:not(:has(> .gform_fileupload_multifile))').each(function () {
    const inputContainer = $(this);
    const currentHtml = $(this).html();
    const { lang } = document.documentElement;
    const label = lang === 'en-US' ? 'Upload file' : 'Bestand toevoegen';
    const errorLabel = lang === 'en-US' ? 'You haven\'t added a file yet.' : 'Je hebt nog geen bestand toegevoegd.';
    const newHtml = `<label class="c-form__file-upload"><i class="fas fa-upload"></i> ${label}${currentHtml}</label><p class="js-upload-text c-form__file-upload-text">${errorLabel}</p>`;
    $(this).html(newHtml);
    const currentInput = $(this).find('input[type="file"]');
    currentInput.change(function () {
      inputContainer.find('.js-upload-text').text(this.files[0].name);
    });
  });

  $addToCalendarSelect.change(function () {
    if ($(this).selectedIndex !== 0) {
      window.open($(this).children('option:selected').val(), 'name');
    }
  });
  $('#download-pdf').click(function () {
    console.log('test');
    const fileName = $(this).data('slug');
    const element = document.getElementById('pdfprint');

    const opt = {
      filename: `${fileName}.pdf`,
      margin: [5, 12, 15, -28],
      jsPDF: { unit: 'mm', format: 'A4', orientation: 'portrait' },
      pagebreak: { mode: 'avoid-all', avoid: ['img', '.container--small', '.container'] },
    };
    html2pdf().set(opt).from(element).toPdf()
      .save();
  });

  initTabs();
  handleTabClicks();

  scrollInContainer($toTopButton);
  scrollInContainer($social);

  accordion = new Accordion(
    '.js-accordion',
    '.js-toggle-accordion',
    '.js-accordion-content',
  );

  accordion.initClickHandling();

});

$(window).on('load', () => {
  let ga;
  let height;
  $ad = $('.js-ad');
  $ad.each(function () {
    ga = $(this).find('.js-google-ad');
    height = ga.find('div').height();
    if (!height) {
      height = '0';
      $(this).css('display', 'none');
    }
    if (!$(this).hasClass('js-multiblock')) {
      $(this).height(height);
    }
  });
});

$('.js-audio-controls').on('loadedmetadata', function () {
  const { duration } = $(this)[0];
  const durationString = durationToMinutesSeconds(duration);
  $('.js-audio-duration').text(durationString);
});

function durationToMinutesSeconds(duration) {
  const minutes = Math.floor(duration / 60);
  const seconds = Math.round(duration % 60);
  return (
    seconds === 60
      ? `${minutes + 1}:00`
      : `${minutes}:${seconds < 10 ? '0' : ''}${seconds}`
  );
}

function setLabelsAfterSliderRendered(slider) {
  var sliderRenderedTimeout = window.setInterval(() => {
    const sliderRendered = afterSliderRendered(slider);
    if (sliderRendered) {
      window.clearInterval(sliderRenderedTimeout);
    }
  }, 5);
}

function afterSliderRendered(slider) {
  const sliderInfo = slider.getInfo();
  if (sliderInfo.isOn) {
    setSliderButtonLabels(sliderInfo);
    return true;
  }
  return false;
}

function setSliderButtonLabels(sliderInfo) {
  $(sliderInfo.nextButton).attr('aria-label', 'slide right');
  $(sliderInfo.prevButton).attr('aria-label', 'slide left');
}

function scrollInContainer($item) {
  if (!isMobile() && !isTablet()) {
    $contentContainer.each(function () {
      const currentContainer = $(this);
      const maxHeight = currentContainer.outerHeight() - 200 - $item.outerHeight();

      $(window).scroll(() => {
        const heightDifference = $(window).scrollTop() - currentContainer.offset().top + $headerHeight;

        if (heightDifference > 0 && heightDifference < maxHeight) {
          $item.addClass('fix');
        } else {
          $item.removeClass('fix');
        }
      });
    });
  }
}

function ajax(action, postData, succesCallback, errorCallback) {
  postData = $.extend({}, postData, { action });

  $.ajax({
    type: 'POST',
    url: linku_ajax_url,
    data: postData,
    dataType: 'json',
    success(response) {
      if (response === '0') {
        if (typeof errorCallback === 'function') errorCallback(response);
      } else if (typeof succesCallback === 'function') succesCallback(response);
    },
    error(response) {
      if (typeof errorCallback === 'function') {
        if (typeof response.responseJSON === 'object') {
          errorCallback(response.responseJSON);
        } else if (typeof response.responseText === 'string' && response.responseText !== '') {
          try {
            const jsonErr = JSON.parse(response.responseText);
            errorCallback(jsonErr);
          } catch (e) {
            errorCallback({ message: 'general' });
          }
        } else {
          errorCallback({ message: 'general' });
        }
      }
    },
  });
}

function initTabs() {
  $tabs.each(function () {
    if ($(this).hasClass('is-active')) {
      const tabName = $(this).data('tab-name');
      const $tabContent = $(`[data-tab-content="${tabName}"]`);
      const $tableOfContent = $tabContent.find('.js-toc');

      if ($tableOfContent.length > 0) {
        monitorActiveTocItem($tabContent[0]);
      }
    }
  });
}

function handleTabClicks() {
  const activeClass = 'is-active';

  $tabs.on('click', function (e) {
    const tabName = $(this).data('tab-name');
    const $tabContent = $(`[data-tab-content="${tabName}"]`);
    const $tableOfContent = $tabContent.find('.js-toc');

    $tableOfContents.removeClass(activeClass);
    if ($tableOfContent.length > 0) {
      $tableOfContent.addClass(activeClass);
      monitorActiveTocItem($tabContent[0]);
    }

    $tabs.removeClass(activeClass);
    $tabsContent.removeClass(activeClass);
    $tabContent.addClass(activeClass);
    $(this).addClass(activeClass);
  });
}

function addClickReadMore($readMoreButton, $readMoreButtonContainer, $readMoreContentContainer) {
  $readMoreButton.click(() => {
    if ($readMoreContentContainer.hasClass('limitShow')) { // open
      $readMoreContentContainer.removeClass('limitShow'); // open
      $readMoreButtonContainer.removeClass('open');
      $readMoreButton.text('Toon minder  -'); // 'Lees meer  +
    } else {
      $readMoreContentContainer.addClass('limitShow'); // open
      $readMoreButtonContainer.addClass('open');
      $readMoreButton.text('Lees meer  +'); // Toon minder  -
    }
  });
}

const getAudioDuration = (elem) => new Promise((resolve) => {
  if (elem.readyState >= 1) {
    resolve(elem.duration);
  }
  $audioElem.on('loadedmetadata', () => {
    resolve(elem.duration);
  });
});

$audioElem = $('.js-audio-controls');
$audioElem.each(function () {
  getAudioDuration($(this)[0]).then((duration) => {
    const durationString = durationToMinutesSeconds(duration);
    $(this).siblings('.js-audio-duration').text(durationString);
  });
});

// Function to add the target="_blank" attribute
function addTargetAttribute() {
  const downloadLinks = document.querySelectorAll('.youzify-media-download a');
  downloadLinks.forEach((link) => {
    if (!link.hasAttribute('target')) {
      link.setAttribute('target', '_blank');
    }
  });
}

// Observe only the specific container
const observerTarget = document.querySelector('.youzify-media-widget');

if (observerTarget) {
  const observer = new MutationObserver((mutationsList) => {
    mutationsList.forEach((mutation) => {
      if (mutation.type === 'childList') {
        // Ensure new nodes are processed
        mutation.addedNodes.forEach((node) => {
          if (node.nodeType === Node.ELEMENT_NODE && node.matches('.youzify-media-group-files')) {
            addTargetAttribute(); // Run the function to set attributes
          }
        });
      }
    });
  });

  // Start observing the target node for child additions
  observer.observe(observerTarget, { childList: true, subtree: true });
}

// Fallback: Attach to document for cases where AJAX content may already exist
document.addEventListener('DOMContentLoaded', addTargetAttribute);
